import { Component, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { AddRemoveFavoriteEventService } from '../../../core/component-communication-services/add-remove-favorite-event/add-remove-favorite-event.service';
import { Favorite } from '../../../core/models/favorite';
import { FavoriteRestService } from '../../../core/rest-services/favorite-rest.service';

@Component({
  selector: 'hl-rename-favorite-modal',
  templateUrl: './rename-favorite-modal.component.html'
})
export class RenameFavoriteModalComponent extends BaseFormModalPopup {
  newName: string;
  private favoriteItem: Favorite;
  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';

  constructor(renderer: Renderer2,
    private addRemoveFavoriteEventService: AddRemoveFavoriteEventService,
    private favoriteRestService: FavoriteRestService) {
    super(renderer);
  }

  renameFavorite(favorite: Favorite) {
    this.favoriteItem = favorite;
    this.newName = this.favoriteItem.name;
    this.show();
  }

  postFormData() {
    this.showSpinner = true;
    this.favoriteRestService.postFavorite({...this.favoriteItem, name: this.newName})
      .pipe(
        finalize(() => {
          this.isFormSubmitted = false;

          this.addRemoveFavoriteEventService.emitFavoriteAddedOrRemoved();

          // close modal
          this.hide();
        })
      )
      .subscribe(() => this.favoriteItem.name = this.newName);
  }
}
