import { Component, Input, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { AddRemoveFavoriteEventService } from '../../../core/component-communication-services/add-remove-favorite-event/add-remove-favorite-event.service';
import { FavoriteRestService } from '../../../core/rest-services/favorite-rest.service';
import { FilterObject } from '../../filtering/filter-object';

@Component({
  selector: 'hl-add-favorite-modal',
  templateUrl: './add-favorite-modal.component.html'
})
export class AddFavoriteModalComponent extends BaseFormModalPopup {
  @Input()
  filter: FilterObject;
  @Input()
  type: string;
  saveName = undefined;

  // add validation message
  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';

  constructor(
    private favoriteRestService: FavoriteRestService,
    private addRemoveFavoriteEventService: AddRemoveFavoriteEventService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  postFormData() {
    // remove the unwanted values since to avoid sending a big object.
    if (this.filter.search?.searchColumns) {
      delete this.filter.search.searchColumns;
    }
    if (this.filter.dateRange?.propertyKey) {
      delete this.filter.dateRange.propertyKey;
    }
    if (this.filter.myEquipment?.keyName) {
      delete this.filter.myEquipment.keyName;
    }
    if (this.filter.myEquipment?.myEquipmentList) {
      delete this.filter.myEquipment.myEquipmentList;
    }
    // Check if operationalStateEquipment
    if (this.filter.operationalStateEquipment?.config) {
      delete this.filter.operationalStateEquipment.config;
    }

    this.showSpinner = true;

    const favoriteObject = {
      name: this.saveName,
      type: this.type,
      filter: JSON.stringify(this.filter)
    };

    // call the backend rest end point
    this.favoriteRestService
      .postFavorite(favoriteObject)
      .pipe(
        finalize(() => {
          this.isFormSubmitted = false;
          this.saveName = '';
          this.addRemoveFavoriteEventService.emitFavoriteAddedOrRemoved();
          this.hide();
        })
      )
      .subscribe();
  }
}
