import { NgModule } from '@angular/core';
import { DiagramModule } from '../diagram/diagram.module';
import { FormsModule } from '@angular/forms';
import { BasicComponentsSharedModule } from '../core/basic-components-shared.module';
import { AddFavoriteModalComponent } from '../shared/modal-popup/add-favorite-modal/add-favorite-modal.component';
import { DeleteFavoriteModalComponent } from '../shared/modal-popup/delete-favorite-modal/delete-favorite-modal.component';
import { RenameFavoriteModalComponent } from '../shared/modal-popup/rename-favorite-modal/rename-favorite-modal.component';
import { PortalsModule } from './portals.module';

@NgModule({
  declarations: [AddFavoriteModalComponent, DeleteFavoriteModalComponent, RenameFavoriteModalComponent],
  exports: [RenameFavoriteModalComponent, AddFavoriteModalComponent, DeleteFavoriteModalComponent],
  imports: [FormsModule, BasicComponentsSharedModule, DiagramModule, PortalsModule]
})
export class FavoriteModalSharedModule {}
